<template>
    <div class="plan-box">
        <el-row>
        <el-col :span="6">
            <div class="cal-box" :style="{height}">
                <van-calendar
                :show-title="false"
                :poppable="false"
                :show-confirm="false"
                show-subtitle
                @select = "onConfirm"
                />
            </div>
        </el-col>
        <el-col :span="18">
            <div class="ri-box" :style="{height}">
                <div class="header-menu">
                    <el-row type="flex" justify="center">
                    <el-col :span="8">
                        <div class="left-m">
                            <el-button type="info" size="mini" round plain>今天</el-button>
                        </div>
                    </el-col>
                    <el-col :span="8" justify="center">
                        <div class="title">
                            {{date}}
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="right-m">
                            <el-select
                            v-model="staff"
                            multiple
                            filterable
                            remote
                            reserve-keyword
                            placeholder="搜索选择员工"
                            :remote-method="searchStaff"
                            :loading="loading">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                        </div>
                    </el-col>
                    </el-row>
                </div>
            </div>
        </el-col>
        </el-row>
    </div>
</template>
<script>
import  Calendar  from 'vant';
import Vue from 'vue';
Vue.use(Calendar);
import {formatTime} from '../../utils/util.js'
export default {
    data(){
        return{
            show:true,
            height:'0px',
            date:null,
            options:[],
            loading:false,
            staff:''
        }
    },
    created(){
        this.height = document.documentElement.clientHeight+'px'
        this.date = formatTime(new Date).split(' ')[0]
    },
    methods:{
        //选择日期
        onConfirm(e){
            this.date = formatTime(e).split(' ')[0]
        },
        searchStaff(e){
            console.log(e)
        }
    }
}
</script>
<style lang="less">
.cal-box{
    width:100%;
    height:100%;
}
.ri-box{
    width:100%;
    height: 100%;
    .header-menu{
        width:100%;
        padding:10px 20px;
        height:50px;
        line-height: 50px;
        border-bottom: 1px rgba(211, 209, 209, 0.1) solid;
    }
    .title{
        font-size: 18px;
        font-weight: 500;
    }
}
</style>